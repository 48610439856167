import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import richTextOptions from '../shared/richTextOptions'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroImage from '../components/HeroImage'
import { rightBack } from '../shared/utilities'
import List from '../components/List'

const Location = ({ pageContext, location }) => {
  const { name, body, logo, slug } = pageContext

  const state = rightBack(slug, '/').toUpperCase()
  const filterEvents = (events, state) => {
    return events.filter(event => event.locationState === state)
  }

  const eventsContext = useContext(GlobalStateContext)
  const eventsList = filterEvents(eventsContext.events, state)

  return (
    <Layout path={location.pathname}>
      <SEO title={name} />
      <HeroImage src={logo.file.url} alt={name} title={name} isLargeImage />
      <div className="container location text-content">
        <Link to="/about/states-territories" className="back-link">
          &larr; State and Territories Ministries
        </Link>
        {body && documentToReactComponents(body.json, richTextOptions)}
        {eventsList.length > 0 && (
          <>
            <h2>Upcoming Events in {state}</h2>
            <List
              data={eventsList}
              itemType="events"
              styleItem="events-preview"
              className="items-container"
            />
          </>
        )}
      </div>
    </Layout>
  )
}

export default Location
